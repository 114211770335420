/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { categoryContainerSX } from '@/components/content/ShopByCategory/styles/container';
import { categoryHeadingSX } from '@/components/content/ShopByCategory/styles/heading';
import { Linkable } from '@/components/blocks/Linkable';
import { CustomProgressIndicator } from '@/components/blocks/CustomProgressIndicator';
import { useCategory } from '@/data/Content/Category';
import { ID } from '@/data/types/Basic';
import { Grid, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import { Img } from '@/components/blocks/MaterialImage';
import { categoryImageSX } from '@/components/content/ShopByCategory/styles/image';
import { S3_IMAGE_PATH_FOR_CATEGORY_AND_PDP } from '@/data/constants/product';

export const Category: FC<{
	id: ID;
	clickAction?: () => void;
}> = ({ id, clickAction }) => {
	const { category, loading } = useCategory(id);
	return loading ? (
		<CustomProgressIndicator />
	) : category ? (
		// TODO Hoist href to top level category object, presentation shouldn't have to handle this structure.
		<Linkable href={category?.seo?.href || ''} onClick={clickAction}>
			<Paper elevation={0} sx={categoryContainerSX}>
				<Grid container alignItems="center">
					<Grid item xs={5} sm={4} md={4} sx={{}}>
						<Img
							width="65"
                            height="65"
							alt={category.shortDescription}
                            aria-hidden="true"
							src={
								S3_IMAGE_PATH_FOR_CATEGORY_AND_PDP +
								(category.fullImage || category.thumbnail || '')
							}
                            loading="lazy"
							sx={categoryImageSX}
						/>
					</Grid>
					<Grid item xs={7} sm={8} md={8} pl={1.25}>
						<Typography variant="h7" sx={categoryHeadingSX}>
							{category.name}
						</Typography>
					</Grid>
				</Grid>
			</Paper>
		</Linkable>
	) : null;
};
